import "./ClientRecordCard.css";
import { ClientData } from "../../interfaces/interfaces";
import { JSX, useMemo, useState } from "react";

interface Props {
    client: ClientData;
    artistName?: string;
    onDelete: (clientId: number) => void;
}

const ClientRecordCard = ({ client, artistName, onDelete }: Props) => {
    const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
    // TODO Make sure the TOTAL is the AMOUNT DUE + the DEPOSIT !!
    const {charge, discounts, deposit, total} = useMemo(() => {
        const ratePerMinute = parseFloat((client.artist_rate / 60).toFixed(2));

        const mealDiscount = client.meal_discount_mins * ratePerMinute;

        const breakDiscount = client.break_discount_mins * ratePerMinute;

        const charge = client.amount_due;

        const discounts = client.vip_discount_amount + mealDiscount + breakDiscount;

        return {
            charge: charge.toFixed(2),
            discounts: discounts.toFixed(2),
            deposit: client.deposit_amount.toFixed(2),
            total: (client.amount_due + client.deposit_amount).toFixed(2),
        };
    }, [client]);

    const cardRows = useMemo(() => {
        const rows: JSX.Element[] = [];
        [
            { key: "Artist", value: artistName },
            { key: "Client Name", value: `${client.client_first_name} ${client.client_last_name}` },
            { key: "Date", value: `${new Date(client.end_time).toDateString()}`},
            { key: "Charge", value: charge},
            { key: "Discounts", value: discounts},
            { key: "Deposit", value: deposit},
            { key: "Total", value: total},
        ].forEach((item, i) => {
            rows.push(
                <div id={`${i}`} className="Client-record-card-row">
                    <div className="Client-record-card-row-title">{item.key}:</div>
                    <div className="Client-record-card-row-value">{item.value}</div>
                </div>
            );
        });

        return rows;
    }, [
        charge,
        client.client_first_name,
        client.client_last_name,
        client.end_time,
        deposit,
        discounts,
        total,
        artistName
    ]);

    return (
        <>
            <div className={`Client-record-card ${showConfirmDelete ? "Border-red" : "Border-white"}`}>
                <div className="Delete-btn-bar">
                    {showConfirmDelete && (
                        <>
                            Delete this record?
                            <button className="Confirm-btn"
                                    onClick={() => {
                                        onDelete(client.id)
                                    }
                                    }>
                                Yes
                            </button>
                            <button className="Confirm-btn"
                                    onClick={() => setShowConfirmDelete(!showConfirmDelete)}>
                                No
                            </button>
                        </>
                    )}
                    {!showConfirmDelete && (
                        <button className="Delete-btn"
                                onClick={
                                    () => setShowConfirmDelete(!showConfirmDelete)
                                }>
                            <i className="fa fa-trash" aria-hidden="true"></i>
                        </button>
                    )}
                </div>
                {cardRows && cardRows.map((element) => element)}
            </div>
        </>
    );
}

export default ClientRecordCard;
