import { useCallback, useState } from "react";
import "./CheckUser.css";
import {findUser} from "../../../api/api";
import {FindUserRequest} from "../../../api/requests";
import {getSessionStorageItem, setSessionStorageItem} from "../../../utilities/utils";
import {IS_LOGGED_IN, USER_ID, USERNAME} from "../../../utilities/globalConstants";

interface Props {
    setUser: (state: {
        username?: string,
        userId?: string,
    }) => void;
}

const CheckUser = ({ setUser }: Props) => {
    const [username, setUsernameChanges] = useState<string>("");
    const [errorNotFound, setErrorNotFound] = useState<boolean>();
    const [errorLength, setErrorLength] = useState<boolean>();
    const onClick = useCallback(async () => {
        // reset errors
        setErrorNotFound(false);
        setErrorLength(false);
        const request: FindUserRequest = {
            userId: username
        }
        await findUser(request).then((response) => {
            if (response && response.userFound && response.username && response.id) {
                setSessionStorageItem(USERNAME, response.username);
                setSessionStorageItem(USER_ID, response.id?.toString());
                setSessionStorageItem(IS_LOGGED_IN, "false")
                setUser({
                    username: getSessionStorageItem(USERNAME) ?? undefined,
                    userId: response.id.toString(),
                });
            }
            else if (response && !response.userFound) {
                setErrorNotFound(!response.userFound);
            }
        });
    }, [username, setErrorNotFound, setUser]);
    return <div className="Check-user-main">
        <h1>Black Spot Crew</h1>
        <form className="Check-user-form">
            <input
                className="Input-style"
                placeholder="Username"
                required
                onChange={
                    (event) =>
                        setUsernameChanges(event.target.value)
                }
            />
            <input value="Continue"
                   className="Input-style"
                   type="button"
                   onClick={async () => {
                if (username.length > 3) {
                    await onClick();
                } else {
                   setErrorLength(true);
                }
            }} />
        </form>
        {errorNotFound && <div>User not found</div>}
        {errorLength && <div>Please enter a full username</div>}
        {/* RULE 👆 username must be > 3 characters long */}
    </div>;
}

export default CheckUser;
