import CheckUser from "./check-user/CheckUser";
import {useCallback, useState} from "react";
import {useNavigate} from "react-router-dom";
import "./Login.css";
import {login} from "../../api/api";
import {LoginRequest} from "../../api/requests";
import {setSessionStorageItem} from "../../utilities/utils";
import {IS_LOGGED_IN, ROLE} from "../../utilities/globalConstants";
import {User} from "../../interfaces/interfaces";

interface Props {
    setLoggedInState: (state: {
        isLoggedIn: boolean,
        username?: string,
    }) => void;
}

const Login = ({setLoggedInState}: Props) => {
    const navigate = useNavigate();
    const [user, setUser] = useState<User>({
        username: undefined,
        userId: undefined,
    });
    const [password, setPassword] = useState<string>();
    const [errorPassword, setErrorPassword] = useState<boolean>(false);
    const onClick = useCallback(async () => {
        setErrorPassword(false);
        const request: LoginRequest = {
            userId: user?.userId,
            password: password,
        }
        await login(request).then((data) => {
            // data.ready is just a duplicate of data.success
            if (data && data.success && data.ready) {
                setLoggedInState({
                    isLoggedIn: data.ready,
                    username: user.username,
                });
                if (data.role && data.ready) {
                    setSessionStorageItem(ROLE, data.role);
                    setSessionStorageItem(IS_LOGGED_IN, data.ready ? "true" : "false");
                    navigate(`/${data.navigate}`);
                }
            } else {
                setErrorPassword(true);
            }
        });
    }, [password, user, setLoggedInState, navigate]);
    const goBack = useCallback(() => {
        setUser({
            username: "",
            userId: "",
        });
    }, [setUser]);
    return <>
        {!user?.userId && !user?.username && <CheckUser setUser={setUser} /> }
        {user?.userId && user?.username &&
            <div className="Check-user-main">
              <h1>Welcome</h1>
              <form className="Check-user-form">
                <div>User: {user.username}</div>
                <input type="password"
                       className="Input-style"
                       placeholder="Password"
                       onChange={(event) => setPassword(event.target.value)}
                />
                <input className="Input-style"
                       value="🔐 Login"
                       type="button"
                       onClick={onClick}
                />
              </form>
              <div className="Username-display">
                <input onClick={goBack} type="button" value="◀ Go Back" />
              </div>
                {errorPassword && <div>Incorrect password</div>}
            </div>
        }
    </>;
};

export default Login;
