import {
    ClientCheckoutRequest,
    CompletedClientDataRequest,
    DeleteClientRecordRequest,
    FindUserRequest,
    GetAllUsersRequest,
    LoginRequest
} from "./requests";
import {
    ClientCheckoutResponse,
    ClientDataResponse,
    DeleteClientRecordResponse,
    FindUserResponse,
    LoginResponse,
    UserDetailsResponse
} from "./responses";
import {BLACK_SPOT_TATTOO_URL, ROLE, USER_ID, USERNAME} from "../utilities/globalConstants";

export const login = async (request: LoginRequest) => {
    const url = `${BLACK_SPOT_TATTOO_URL}/api/login`;
    const headers = new Headers({
        "Content-Type": "application/json",
    });
    return await fetch(url, {
        method: "POST",
        body: JSON.stringify(request),
        headers,
    }).then((response) => response.json())
        .then((data: LoginResponse) => data);
};

export const findUser = async (request: FindUserRequest) => {
    const url = `${BLACK_SPOT_TATTOO_URL}/api/users`;
    const headers = new Headers({
        "Content-Type": "application/json",
    });
    return await fetch(url, {
        method: "POST",
        body: JSON.stringify(request),
        headers: headers,
    }).then((response) => response.json())
        .then((data: FindUserResponse) => data);
}

export const submitClientCheckout = async (request: ClientCheckoutRequest) => {
    const url = `${BLACK_SPOT_TATTOO_URL}/api/checkout`;
    const headers = new Headers({
        "Content-Type": "application/json",
    });
    return await fetch(url, {
        method: "POST",
        body: JSON.stringify(request),
        headers: headers,
    }).then((response) => response.json())
        .then((response: ClientCheckoutResponse) => response);
}

export const getClientsByUser = async (request: CompletedClientDataRequest) => {
    const url = `${BLACK_SPOT_TATTOO_URL}/api/clients-by-user`;
    const headers = new Headers({
        "Content-Type": "application/json",
    });
    return await fetch(url, {
        method: "POST",
        body: JSON.stringify(request),
        headers: headers,
    }).then((response) => response.json())
        .then((response: ClientDataResponse) => {
            if (response.success) {
                return response.result;
            }
        });
}

export const getAllUsers = async () => {
    const userId = window.sessionStorage.getItem(USER_ID);
    const username = window.sessionStorage.getItem(USERNAME);
    const role = window.sessionStorage.getItem(ROLE);
    const url = `${BLACK_SPOT_TATTOO_URL}/api/all-users`;
    const request: GetAllUsersRequest = {
        role: role ?? "",
        userId: userId ?? "",
        username: username ?? "",
    };
    const headers = new Headers({
        "Content-Type": "application/json",
    });
    return await fetch(url, {
        method: "POST",
        body: JSON.stringify(request),
        headers: headers,
    })
        .then((response) => response.json())
        .then((response: UserDetailsResponse) => {
            return response.users;
        });
}

export const deleteClientRecord = async (request: DeleteClientRecordRequest) => {
    const url = `${BLACK_SPOT_TATTOO_URL}/api/delete-client-record`;
    const headers = new Headers({
        "Content-Type": "application/json",
    });
    return await fetch(url, {
        method: "PUT",
        body: JSON.stringify(request),
        headers: headers,
    })
        .then((response) => response.json())
        .then((response: DeleteClientRecordResponse) => response);
};
