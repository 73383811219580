import {Route, Routes} from "react-router-dom";
import "./BaseRoutes.css";
import HomePage from "../pages/home/HomePage";
import Settings from "../pages/settings/Settings";
import NavLinks from "../nav-links/NavLinks";
import Checkout from "../pages/checkout/Checkout";
import NotFound from "../pages/NotFound";
import ArtistProfileOverview from "../pages/artist-profile-overview/ArtistProfileOverview";

interface Props {
    setLoggedInState: (state: {
        isLoggedIn: boolean,
        username: string,
    }) => void;
}

const BaseRoutes = ({ setLoggedInState }: Props) => {
    return(
        <>
            <NavLinks setLoggedInState={setLoggedInState}/>
            <Routes>
                <Route index element={<HomePage />} />
                <Route path="/home" element={<HomePage />} />
                <Route path="/checkout" element={<Checkout />}/>
                <Route path="/artist-profile-overview" element={<ArtistProfileOverview />}/>
                <Route path="/settings" element={<Settings />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </>
    )
};

export default BaseRoutes;
