import "./CheckoutConfirmDialog.css"
import {ClientConfirmCheckoutData} from "../../interfaces/interfaces";
import {useMemo} from "react";

interface Props {
    setDisplay: (display: boolean) => void;
    onSubmit: () => void;
    clientData: ClientConfirmCheckoutData;
}

const CheckoutConfirmDialog = ({ setDisplay, onSubmit, clientData }: Props) => {
    const {name, charge, paidDeposit, amountDue, artistName} = clientData;

    const discounts = useMemo(() => {
        return (charge - amountDue - (paidDeposit));
    }, [charge, amountDue, paidDeposit]);

    return (
        <div className="Dialog-container">
            <div className="Dialog-main">
                <div>
                    <h2>Confirm Checkout</h2>
                    {artistName && (
                        <h4>Artist: {artistName}</h4>
                    )}
                    <h5>Client: {name}</h5>
                    <h5>Charge: ${charge.toFixed(2)}</h5>
                    {!!discounts && discounts > 0 && (
                        <h5>Discounts: (${discounts.toFixed(2)})</h5>
                    )}
                    {paidDeposit && (
                        <h5>Deposit: {`$${paidDeposit.toFixed(2)}`}</h5>
                    )}
                    <h3>Total: ${amountDue}</h3>
                </div>
                <div className="Dialog-btn-row">
                    <input className=""
                           value="Go back"
                           type="submit"
                           onClick={(event) => {
                               event.preventDefault();
                               setDisplay(false);
                           }}
                    />
                    <input className="Submit-btn"
                           value="Submit"
                           type="submit"
                           onClick={(event) => {
                               event.preventDefault();
                               onSubmit();
                           }}
                    />
                </div>
            </div>
        </div>
    );
}

export default CheckoutConfirmDialog;
