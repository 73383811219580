import {useMemo} from "react";
import "./GoalRuler.css";

interface Props {
    amounts?: number[];
}

const GoalRuler = ({amounts}: Props) => {
    const goalLine = useMemo(() => {
        let widthOfGoal = 0;
        amounts?.forEach(amount => {
            widthOfGoal += amount;
        })
        return widthOfGoal < 1000 ? `${widthOfGoal / 10.00}%` : "100%";
    }, [amounts]);
    return (
        <div>
            <h4>Weekly Goal</h4>
            <div className="Ruler-container">
                <div className="Ruler">
                    <div className="Rule Glow" style={{ width: goalLine ?? "0" }}></div>
                </div>
                <div className="Ruler-ticks-bar">
                    <span className="Ruler-tick Ruler-tick-empty" ></span>
                    <span className={`Ruler-tick ${parseFloat(goalLine) >= 50 ? "Rule-tick-reached" : "Ruler-tick-numbered"}`} >500</span>
                    <span className={`Ruler-tick ${parseFloat(goalLine) >= 75 ? "Rule-tick-reached" : "Ruler-tick-numbered"}`} >750</span>
                    <span className={`Ruler-tick ${parseFloat(goalLine) >= 100 ? "Rule-tick-reached" : "Ruler-tick-numbered"}`} >1000</span>
                </div>
            </div>
        </div>
    );
}

export default GoalRuler;
