import "./Home.css"
const HomePage = () => {
    return (
        <div className="Home-layout">
            <h1 className="Home">Home Page</h1>
            <h3>Welcome to the Black Spot Tattoo app</h3>
            <span className="Home-body">
                Use the navigation links above to complete client checkout 🤝
                or navigate to the Artist Profile Overview 🎨 and check your
                weekly progress.
            </span>
            <span className="Home-body">
                This app is currently being developed so don't be surprised
                if something changes in the future.
            </span>
        </div>
    );
};

export default HomePage;
