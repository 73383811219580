import { Link } from "react-router-dom";
import "./NavLinks.css";
import {useState} from "react";
import {ROLE, USER_ID, USERNAME} from "../utilities/globalConstants";

interface Props {
    setLoggedInState: (state: {
        isLoggedIn: boolean,
        username: string,
    }) => void;
}

const NavLinks = ({ setLoggedInState }: Props) => {
    // This useState is the beginning of the process for fixing page refresh
    // const [refreshUrl, setRefreshUrl] = useState<string>("");
    const [displayMobileMenu, setDisplayMobileMenu] = useState<boolean>(false);
    const onClick = () => {
        setDisplayMobileMenu(!displayMobileMenu)
        window.sessionStorage.setItem("isLoggedIn", "false");
        window.sessionStorage.removeItem(USERNAME);
        window.sessionStorage.removeItem(ROLE);
        window.sessionStorage.removeItem(USER_ID);
        setLoggedInState({
            isLoggedIn: false,
            username: "",
        });
    };
    return (<div className="Nav-bar Nav-bar-min">
        <div className="Title">
            <div className="Title-bar">
                ⚫ Black spot tattoo
                <button className="Menu-button"
                        onClick={() => setDisplayMobileMenu(!displayMobileMenu)}
                ><i className="fa fa-bars"></i></button>
            </div>
        </div>

        {window.outerWidth > 900 && (
            <div className="Button-bar">
                <button className="Button-link">
                    <Link to="home" className="Link">🏡 Home</Link>
                </button>
                <button className="Button-link">
                    <Link to="checkout" className="Link">🤝 Checkout</Link>
                </button>
                <button className="Button-link">
                    <Link to="artist-profile-overview" className="Link">🎨 Artist Profile Overview</Link>
                </button>
                <button className="Button-link">
                    <Link to="settings" className="Link">⚙ Settings</Link>
                </button>
                <button onClick={onClick} className="Logout">
                    💀 Logout
                </button>
            </div>
        )}
        {displayMobileMenu &&
            <div className="Button-bar-mobile">
              <Link to="home" className="Link"
                    onClick={() => setDisplayMobileMenu(!displayMobileMenu)}
              >🏡 Home</Link>
              <Link to="checkout" className="Link"
                    onClick={() => setDisplayMobileMenu(!displayMobileMenu)}
              >🤝 Checkout</Link>
              <Link to="artist-profile-overview" className="Link"
                    onClick={() => setDisplayMobileMenu(!displayMobileMenu)}
              >🎨 Artist Profile Overview</Link>
              <Link to="settings" className="Link"
                    onClick={() => setDisplayMobileMenu(!displayMobileMenu)}
                >🔧 Settings</Link>
                <Link to="*" onClick={onClick} className="Link">
                    💀 Logout
                </Link>
            </div>
        }

    </div>)
};

export default NavLinks;
