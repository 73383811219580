

const NotFound = () => {
    return (
        <div style={{ margin: '0 auto', width: 'fit-content' }}>
            <h1 className="Test">404: Not Found</h1>
        </div>
    );
}

export default NotFound;
