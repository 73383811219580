import {useEffect, useState} from "react";
import {deleteClientRecord, getAllUsers, getClientsByUser} from "../../api/api";
import {CompletedClientDataRequest, DeleteClientRecordRequest} from "../../api/requests";
import "./ArtistProfileOverview.css";
import {ArtistDetails, ClientData} from "../../interfaces/interfaces";
import GoalRuler from "../../components/goal-ruler/GoalRuler";
import {USER_ID} from "../../utilities/globalConstants";
import ClientRecordCard from "../../components/client-record-card/ClientRecordCard";

const ArtistProfileOverview = () => {
    const [userId, setUserId] = useState<string | null>();

    const [data, setData] = useState<ClientData[]>([]);

    const [users, setUsers] = useState<ArtistDetails[]>([]);

    const [currentArtist, setCurrentArtist] = useState<ArtistDetails>();

    useEffect(() => {
        setUserId(window.sessionStorage.getItem(USER_ID));
    }, []);

    useEffect(() => {
        const request: CompletedClientDataRequest = {
            username: userId ?? ""
        }
        getClientsByUser(request).then(data => setData(data ?? []));
    }, [userId]);

    useEffect(() => {
        getAllUsers().then((users) => setUsers(users));
    }, []);

    const onDelete = (clientId: number) => {
        const userId = window.sessionStorage.getItem(USER_ID);
        if (userId) {
            const request: DeleteClientRecordRequest = {
                clientId: clientId,
                userId: userId,
            };
            deleteClientRecord(request).then(data => {
                if (data && data.success) {
                    const request: CompletedClientDataRequest = {
                        username: currentArtist?.id?.toString() ?? ""
                    }
                    getClientsByUser(request).then(data => setData(data ?? []));
                }
            })
        }
    };

    return (
        <div className="Dashboard">
            <h1>Artist Profile Overview</h1>
            <div className="Apo-container">
                <div className="Metrics-container">
                    <h3>Metrics</h3>
                    <GoalRuler amounts={data.map(client => client.amount_due)}/>
                </div>
                <div>{currentArtist?.name}</div>
                <select className="Input-style"
                        onChange={(event) => {
                            if (event.target.value) {
                                const userId = parseInt(event.target.value);
                                const user = users.find((user) => user.id === userId);
                                if (user) {
                                    setCurrentArtist(user);
                                    setUserId(user?.id?.toString())
                                }
                            }
                        }}
                >
                    {users && users.map((user) =>
                        <option value={user.id}
                                key={user.id}
                        >{user.name}</option>
                    )}
                </select>
                <div>
                    <div className="Client-display">
                        {data.map((client, i) => (
                            <ClientRecordCard key={client.id}
                                              client={client}
                                              artistName={currentArtist?.name}
                                              onDelete={onDelete}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>);
};

export default ArtistProfileOverview;
