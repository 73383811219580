import Login from "./pages/login/Login";
import BaseRoutes from "./routes/BaseRoutes";
import "./App.css";
import {useMemo, useState} from "react";
import {ADMIN, ARTIST, IS_LOGGED_IN, ROLE, USER_ID} from "./utilities/globalConstants";
import AdminRoutes from "./routes/admin-routes/AdminRoutes";

function App() {
  const [loggedInState, setLoggedInState] = useState({
    isLoggedIn: window.sessionStorage.getItem(IS_LOGGED_IN) === "true",
    userId: window.sessionStorage.getItem(USER_ID)
  });

  const role = window.sessionStorage.getItem(ROLE);

  return useMemo(
      () =>
          <div className="App-page">
            {loggedInState.isLoggedIn &&
                role === ADMIN && (
                    <AdminRoutes setLoggedInState={setLoggedInState}/>
                )}
            {loggedInState.isLoggedIn &&
                role === ARTIST && (
                    <BaseRoutes setLoggedInState={setLoggedInState}/>
                )}
            {
              !loggedInState.isLoggedIn &&
              <Login setLoggedInState={setLoggedInState}/>
            }
          </div>,
      [loggedInState, role]
  );
}

export default App;
