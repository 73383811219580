import "./CheckoutAdmin.css"
import { useCallback, useEffect, useMemo, useState } from "react";
import { ClientCheckoutRequest } from "../../api/requests";
import { getAllUsers, submitClientCheckout } from "../../api/api";
import { useNavigate } from "react-router-dom";
import CheckoutConfirmDialog from "../../components/checkout-confirm-dialog/CheckoutConfirmDialog";
import { ArtistDetails } from "../../interfaces/interfaces";

const CheckoutAdmin = () => {
    const navigate = useNavigate();

    const { oneHourPrior, startingAmPm, currentMinutes, currentAmPm, currentHour} = useMemo(() => {
        const today = new Date();
        const mins = today.getMinutes()
        let currentHour = today.getHours();
        let priorHour = 0;
        if (currentHour === 13) {
            priorHour = 12;
        } else {
            priorHour = currentHour > 12 ? currentHour - 13 : currentHour - 1;
        }
        return {
            oneHourPrior: `${priorHour}`,
            startingAmPm: (currentHour - 1) < 12 ? "AM" : "PM",
            currentMinutes: mins < 10 ? `0${mins}` : `${mins}`,
            currentHour: currentHour < 13 ? `${currentHour}` : `${currentHour - 12}`,
            currentAmPm: currentHour < 12 ? "AM" : "PM",
        }
    }, []);

    const [displayDialog, setDisplayDialog] = useState<boolean>(false)

    const [clientFirstName, setClientFirstName] = useState<string>("");

    const [clientLastName, setClientLastName] = useState<string>("");

    const [hourStart, setHourStart] = useState<string>(oneHourPrior);

    const [hourEnd, setHourEnd] = useState<string>(currentHour);

    const [minuteStart, setMinuteStart] = useState<string>(currentMinutes);

    const [minuteEnd, setMinuteEnd] = useState<string>(currentMinutes);

    const [amPmStart, setAmPmStart] = useState<string>(startingAmPm);

    const [amPmEnd, setAmPmEnd] = useState<string>(currentAmPm);

    const [startDate, setStartDate] = useState<string>(new Date().toISOString().slice(0, 10));

    const [endDate, setEndDate] = useState<string>(new Date().toISOString().slice(0, 10));

    const [paidDeposit, setPaidDeposit] = useState<number>(0);

    const [users, setUsers] = useState<ArtistDetails[]>([]);

    const [currentArtist, setCurrentArtist] = useState<ArtistDetails>();

    const [currentArtistRate, setCurrentArtistRate] = useState<number>(0);

    const [discountDisplay, setDiscountDisplay] = useState<{
        meal: boolean,
        break: boolean,
        vip: boolean,
    }>({
        meal: false,
        break: false,
        vip: false,
    })

    const [discountValues, setDiscountValues] = useState<{
        meal: number,
        break: number,
        vip: number,
    }>({
        meal: 0,
        break: 0,
        vip: 0.00,
    });

    const {hourOptionValues, minuteOptionValues} = useMemo(() => {
        const minutes = ["00"];
        const hours = [];
        for (let i = 1; i < 60; i++) {
            if (i < 10) minutes.push(`0${i}`);
            else minutes.push(`${i}`);
            if (i < 13) hours.push(`${i}`);
        }
        return { hourOptionValues: hours, minuteOptionValues: minutes };
    }, []);

    const createDateString = (hour: string, min: string, amPm: string, date: string) => {
        let timeString = "";

        if (amPm === "PM" && parseInt(hour) !== 12) {
            timeString = `${parseInt(hour) + 12}:${min}:00`;
        } else {
            timeString = `${parseInt(hour) < 10 ? "0" + hour : hour}:${min}:00`;
        }

        const dateParts = date.toString().split(" ");

        const filteredDateParts = dateParts.filter((_, i) => i < 4);

        const fullDateString = filteredDateParts.join(" ") + " " + timeString;

        return new Date(fullDateString);
    }

    const {totalMinutes, charge, amountDue} = useMemo(() => {
        // Todo Sort out the total minutes, charge and amount due
        //      total minutes: end time minus start time
        //      charge: total minutes multiplied by the ratePerMinute
        //      amount due: charge minus the discounts and deposit (if paid)
        const ratePerMinute = parseFloat((currentArtistRate / 60).toFixed(6));

        const start = createDateString(hourStart, minuteStart, amPmStart, startDate);
        const end = createDateString(hourEnd, minuteEnd, amPmEnd, endDate);
        const calculatedMinutes = ((end.getTime() - start.getTime()) / 1000) / 60;

        const charge = (calculatedMinutes * ratePerMinute);

        const discountedMinutes = calculatedMinutes - (
            (discountDisplay.meal ? discountValues.meal : 0) +
            (discountDisplay.break ? discountValues.break : 0)
        );
        const amountDue = (discountedMinutes * ratePerMinute) - (paidDeposit) - (discountValues.vip);

        return {
            amountDue: amountDue.toFixed(2),
            charge: charge.toFixed(2),
            totalMinutes: calculatedMinutes > 0 ? calculatedMinutes : 0,
        };
    }, [
        amPmEnd,
        amPmStart,
        hourEnd,
        hourStart,
        minuteEnd,
        minuteStart,
        discountValues,
        discountDisplay,
        paidDeposit,
        startDate,
        endDate,
        currentArtistRate,
    ]);

    const onSubmit = useCallback(async () => {
        const userId = currentArtist?.id?.toString();
        if (userId) {
            const request: ClientCheckoutRequest = {
                firstName: clientFirstName,
                lastName: clientLastName,
                startTime: createDateString(hourStart, minuteStart, amPmStart, startDate).toISOString(),
                endTime: createDateString(hourEnd, minuteEnd, amPmEnd, endDate).toISOString(),
                paidDeposit: paidDeposit ? 1 : 0,
                depositAmount: paidDeposit,
                discounts: {
                    meal: discountDisplay.meal ? discountValues.meal : 0,
                    break: discountDisplay.break ? discountValues.break : 0,
                    vip: discountDisplay.vip ? discountValues.vip : 0,
                },
                artistId: userId,
                amountDue: parseFloat(amountDue),
                artistRate: currentArtistRate,
            }
            await submitClientCheckout(request)
                .then(response => {
                    if (response.success && response.result.affectedRows) {
                        navigate("/home");
                    }
            });
        }
    }, [
        clientFirstName,
        clientLastName,
        hourStart,
        minuteStart,
        amPmStart,
        hourEnd,
        minuteEnd,
        amPmEnd,
        paidDeposit,
        discountDisplay,
        discountValues,
        amountDue,
        navigate,
        startDate,
        endDate,
        currentArtistRate,
        currentArtist?.id,
    ]);

    useEffect(() => {
        getAllUsers().then((users) => {
            setUsers(users);
        });
    }, []);

    return <>
        <div className="Main">
            <form className="Form-dims Form-dims-style">
                <h2 className="Page-title">Checkout</h2>
                <div className="Flex-row-center">
                    <div className="Section-title">Client:</div>
                    <div className="Row-one-dims Flex-col-center">
                        <input type="text"
                               onChange={(event) => setClientFirstName(event.target.value)}
                               placeholder="First Name"
                               className="Input-style"
                               required={true}
                        />
                        <input type="text"
                               onChange={(event) => setClientLastName(event.target.value)}
                               placeholder="Last Name"
                               className="Input-style"
                        />
                    </div>
                </div>
                <div className="Row-two-dims">
                    <div className="Flex-row-center" style={{ flexWrap: "wrap"}}>
                        <div className="Section-title">Start:</div>
                        <div className="Time">
                            <select
                                value={hourStart}
                                onChange={(event) =>
                                    setHourStart(event.target.value)}
                                className="Input-style Select-dims">
                                {hourOptionValues.map((value) =>
                                    <option value={value} key={value}>{value}</option>
                                )}
                            </select>
                            {":"}
                            <select
                                value={minuteStart}
                                onChange={(event) =>
                                    setMinuteStart(event.target.value)}
                                className="Input-style Select-dims">
                                {minuteOptionValues.map((value) =>
                                    <option value={value} key={value}>{value}</option>
                                )}
                            </select>
                            {" "}
                            <select
                                value={amPmStart}
                                onChange={(event) =>
                                    setAmPmStart(event.target.value)}
                                className="Input-style Select-dims">
                                {["AM", "PM"].map((value) =>
                                    <option value={value} key={value}>{value}</option>
                                )}
                            </select>
                        </div>
                        <div>
                            <input value={startDate}
                                   type="date"
                                   className="Date-picker-style"
                                   onChange={(event) => setStartDate(event.target.value)}
                            />
                        </div>
                    </div>
                    {/*    END TIME    */}
                    <div className="Flex-row-center" style={{ flexWrap: "wrap"}}>
                        <div className="Section-title">End:</div>
                        <div className="Time">
                            <select
                                value={hourEnd}
                                onChange={(event) =>
                                    setHourEnd(event.target.value)}
                                className="Input-style Select-dims">
                                {hourOptionValues.map((value) =>
                                    <option value={value} key={value}>{value}</option>
                                )}
                            </select>
                            {":"}
                            <select
                                value={minuteEnd}
                                onChange={(event) =>
                                    setMinuteEnd(event.target.value)}
                                className="Input-style Select-dims">
                                {minuteOptionValues.map((value) =>
                                    <option value={value} key={value}>{value}</option>
                                )}
                            </select>
                            {" "}
                            <select
                                value={amPmEnd}
                                onChange={(event) =>
                                    setAmPmEnd(event.target.value)}
                                className="Input-style Select-dims">
                                {["AM", "PM"].map((value) =>
                                    <option value={value} key={value}>{value}</option>
                                )}
                            </select>
                        </div>
                        <div>
                            <input value={endDate}
                                   type="date"
                                   className="Date-picker-style"
                                   onChange={(event) => setEndDate(event.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="Flex-row-center">
                    <div className="Section-title">Artist:</div>
                    <select className="Input-dims Input-style"
                            onChange={(event) => {
                                if (event.target.value) {
                                    const userId = parseInt(event.target.value);
                                    const user = users.find((user) => user.id === userId);
                                    if (user) {
                                        setCurrentArtist(user);
                                        setCurrentArtistRate(user.rate);
                                    }
                                }
                            }}
                    >
                        {users && users.map((user) =>
                            <option value={user.id}
                                    key={user.id}
                            >{user.name}</option>
                        )}
                    </select>
                    <div className="Section-title">Rate:</div>
                    <input className="Input-dims Input-style"
                           style={{ width: "25%" }}
                           placeholder="$ Amount"
                           type="number"
                           min={0}
                           value={currentArtistRate}
                           onChange={(event) => {
                               if (event.target.value) {
                                   const rateChange = parseInt(event.target.value);
                                   setCurrentArtistRate(rateChange);
                               } else {
                                   setCurrentArtistRate(0);
                               }
                           }}
                    />
                </div>
                <div className="Row-three-dims Total-mins">
                    <h4>Total Minutes: {totalMinutes}</h4>
                    <h4>Charge: ${charge}</h4>
                </div>
                <div className="Flex-row-center" style={{ justifyContent: "flex-start"}}>
                    <div className="Section-title">Deposit:</div>
                    <input type="text"
                           className="Input-dims Input-style"
                           placeholder="$ Amount"
                           onChange={(event) => {
                               event.target.value && parseFloat(event.target.value)
                                   ? setPaidDeposit(parseFloat(event.target.value))
                                   : setPaidDeposit(0);
                           }}
                    />
                </div>
                <div className="Row-four-dims">
                    <div className="Discount">
                        <input
                            onClick={() =>{
                                if (discountDisplay.meal) {
                                    setDiscountValues({
                                        meal: 0,
                                        break: discountValues.break,
                                        vip: discountValues.vip,
                                    })
                                }
                                setDiscountDisplay({
                                    meal: !discountDisplay.meal,
                                    break: discountDisplay.break,
                                    vip: discountDisplay.vip,
                                })

                            }}
                            className="Discount-btn"
                            type="button"
                            value="Meal"/>
                        {discountDisplay.meal &&
                            <input type="number"
                                   placeholder={"Minutes"}
                                   inputMode="numeric"
                                   min={0}
                                   onChange={(event) => {
                                       if (isNaN(parseInt(event.currentTarget.value)) || parseInt(event.currentTarget.value) < 0) {
                                           setDiscountValues({
                                               meal: 0,
                                               break: discountValues.break,
                                               vip: discountValues.vip,
                                           })
                                       } else {
                                           setDiscountValues({
                                               meal: parseInt(event.currentTarget.value),
                                               break: discountValues.break,
                                               vip: discountValues.vip,
                                           })
                                       }
                                   }}
                                   onClick={(event) => {
                                       if (isNaN(parseInt(event.currentTarget.value)) || parseInt(event.currentTarget.value) < 0) {
                                           setDiscountValues({
                                               meal: 0,
                                               break: discountValues.break,
                                               vip: discountValues.vip,
                                           })
                                       } else {
                                           setDiscountValues({
                                               meal: parseInt(event.currentTarget.value),
                                               break: discountValues.break,
                                               vip: discountValues.vip,
                                           })
                                       }
                                   }}
                            />
                        }
                    </div>
                    <div className="Discount">
                        <input
                            onClick={() => {
                                if (discountDisplay.break) {
                                    setDiscountValues({
                                        meal: discountValues.meal,
                                        break: 0,
                                        vip: discountValues.vip,
                                    })
                                }
                                setDiscountDisplay({
                                    meal: discountDisplay.meal,
                                    break: !discountDisplay.break,
                                    vip: discountDisplay.vip,
                                })
                            }}
                            className="Discount-btn"
                            type="button"
                            value="Break"/>
                        {discountDisplay.break &&
                            <input type="number"
                                   placeholder={"Minutes"}
                                   min={0}
                                   onChange={(event) => {
                                       if (isNaN(parseInt(event.currentTarget.value)) || parseInt(event.currentTarget.value) < 0) {
                                           setDiscountValues({
                                               meal: discountValues.meal,
                                               break: 0,
                                               vip: discountValues.vip,
                                           })
                                       } else {
                                           setDiscountValues({
                                               meal: discountValues.meal,
                                               break: parseInt(event.currentTarget.value),
                                               vip: discountValues.vip,
                                           })
                                       }
                                   }}
                                   onClick={(event) => {
                                       if (isNaN(parseInt(event.currentTarget.value)) || parseInt(event.currentTarget.value) < 0) {
                                           setDiscountValues({
                                               meal: discountValues.meal,
                                               break: 0,
                                               vip: discountValues.vip,
                                           })
                                       } else {
                                           setDiscountValues({
                                               meal: discountValues.meal,
                                               break: parseInt(event.currentTarget.value),
                                               vip: discountValues.vip,
                                           })
                                       }
                                   }}
                            />
                        }
                    </div>
                    <div className="Discount">
                        <input
                            onClick={() => {
                                if (discountValues.vip) {
                                    setDiscountValues({
                                        meal: discountValues.meal,
                                        break: discountValues.break,
                                        vip: 0,
                                    })
                                }
                                setDiscountDisplay({
                                    meal: discountDisplay.meal,
                                    break: discountDisplay.break,
                                    vip: !discountDisplay.vip,
                                })
                            }}
                            className="Discount-btn"
                            type="button"
                            value="VIP"
                        />
                        {discountDisplay.vip &&
                            <input type="number"
                                   placeholder={"$ Amount"}
                                   min={0}
                                   onChange={(event) => {
                                       if (isNaN(parseInt(event.currentTarget.value)) || parseInt(event.currentTarget.value) < 0) {
                                           setDiscountValues({
                                               meal: discountValues.meal,
                                               break: discountValues.break,
                                               vip: 0,
                                           })
                                       } else {
                                           setDiscountValues({
                                               meal: discountValues.meal,
                                               break: discountValues.break,
                                               vip: parseFloat(event.currentTarget.value),
                                           })
                                       }
                                   }}
                                   onClick={(event) => {
                                       if (isNaN(parseInt(event.currentTarget.value)) || parseInt(event.currentTarget.value) < 0) {
                                           setDiscountValues({
                                               meal: discountValues.meal,
                                               break: discountValues.break,
                                               vip: 0,
                                           })
                                       } else {
                                           setDiscountValues({
                                               meal: discountValues.meal,
                                               break: discountValues.break,
                                               vip: parseFloat(event.currentTarget.value),
                                           })
                                       }
                                   }}
                            />
                        }
                    </div>
                </div>

                <div className="Submit">
                    <div className="Amount">
                        <h3>Amount Due</h3>
                        <h2 id="amountDue">$ {amountDue}</h2>
                    </div>
                    <input className="Submit-btn"
                           value="Continue"
                           type="submit"
                           onClick={(event) => {
                               event.preventDefault();
                               setDisplayDialog(true);
                           }}
                    />
                </div>
            </form>
        </div>
        {displayDialog && (
            <CheckoutConfirmDialog
                setDisplay={setDisplayDialog}
                onSubmit={onSubmit}
                clientData={{
                    charge: parseFloat(charge),
                    paidDeposit: paidDeposit,
                    name: `${clientFirstName} ${clientLastName}`,
                    amountDue: parseFloat(amountDue),
                    artistName: currentArtist?.name ?? "",
                }}
            />
        )}
    </>;
};

export default CheckoutAdmin;
